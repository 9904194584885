import React, {useEffect, useState} from "react"
import Img from 'gatsby-image';
import RellaxWrapper from 'react-rellax-wrapper'
import {Link} from 'gatsby';
import { navigate } from "gatsby"
import { useInView } from 'react-intersection-observer';
import {Helmet} from 'react-helmet'
import "../sass/pages/_blog-index.scss";
import Seo from '../components/seo'
import styled from 'styled-components'
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";
import { graphql } from 'gatsby'

const Hero = styled.div`
background:linear-gradient(to top, ${props => props.col1}, ${props => props.col2} );
`

const Blog = ({ data, pageContext }) => {
  const posts = data.allPosts.edges;
  const firstpost = data.firstPost.edges;
  const cats = data.allWpBlogCat.nodes;
  const seoTitle = data.wpPage.seo.title
  const seoDesc = data.wpPage.seo.metaDesc
  const {totalPages, pageNumber} = pageContext;
  const [isActive, setIsActive] = useState('');

  //console.log(pageContext)
  useEffect(() => {
    if(typeof document !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', '')
      
      const currentPage = window.location.pathname.split("/").pop();
      setIsActive(currentPage);

      const hero = document.querySelector('.hero_post')
      const filters = document.querySelector('.filters')
      hero.classList.add('is_inview')
      filters.classList.add('is_inview')
    }
  }, [])

  const handleOnChange = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var option =  optionElement.getAttribute('data-slug');
    var cat =  optionElement.getAttribute('data-cat');
    
    navigate(option)
    setIsActive(cat);

  }


  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  return (
    <>
      <Seo 
      title={seoTitle} 
      description={seoDesc} 
      date={data.wpPage.date}
      slug={'/'+data.wpPage.slug+'/'}
      robotsFollow={data.wpPage.seo.metaRobotsNofollow}
      robotsIndex={data.wpPage.seo.metaRobotsNoindex}
      canonicalUrl={data.wpPage.seo.canonical.replace('blog', 'insights')}
      />
      {data.wpPage.seo.schema.raw !== undefined ? 
        <SchemaLinksToAbsolute schemaRaw={data.wpPage.seo.schema.raw} /> 
      : "" }
      {/* <Helmet>
        <script type="application/ld+json">
          {
            `{
              "@context":"https://schema.org",
              "@graph":[
                  {
                    "@type":"CollectionPage",
                    "@id":"${process.env.GATSBY_SITE_URL}/blog/${pageNumber !== 1 ? 'page/'+pageNumber : ''}#webpage",
                    "url":"${process.env.GATSBY_SITE_URL}/blog/${pageNumber !== 1 ? 'page/'+pageNumber+'/' : ''}",
                    "name":"Online Marketing Blog | Edge45\u00ae",
                    "isPartOf":{
                        "@id":"${process.env.GATSBY_SITE_URL}#website"
                    },
                    "description":"Edge45\u00ae is passionate about all things regarding online marketing. Read some of the things we have written here on our marketing blog.",
                    "breadcrumb":{
                        "@id":"${process.env.GATSBY_SITE_URL}/blog/${pageNumber !== 1 ? 'page/'+pageNumber : ''}#breadcrumb"
                    },
                    "inLanguage":"en-GB",
                    "potentialAction":[
                        {
                          "@type":"ReadAction",
                          "target":[
                              "${process.env.GATSBY_SITE_URL}/blog/${pageNumber !== 1 ? 'page/'+pageNumber+'/' : ''}"
                          ]
                        }
                    ]
                  },
                  {
                    "@type":"BreadcrumbList",
                    "@id":"${process.env.GATSBY_SITE_URL}/blog/${pageNumber !== 1 ? 'page/'+pageNumber : ''}#breadcrumb",
                    "itemListElement":[
                        {
                          "@type":"ListItem",
                          "position":1,
                          "name":"Home",
                          "item":"${process.env.GATSBY_SITE_URL}/"
                        },
                        {
                          "@type":"ListItem",
                          "position":2,
                          "name":"Blog"
                        }
                    ]
                  }
              ]
            }`
          }
        </script>
      </Helmet> */}
      <div className="blog-index">
        
        <div className="wrapper-large">      
          <Hero className={`hero_post`} ref={ref} col1={firstpost[0].node.acf_customColours.customColour1}  col2={firstpost[0].node.acf_customColours.customColour2}>
            <div className="scrolling_image">
              <div className="img_wrap" >
                {firstpost[0]?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
               <Img fluid={firstpost[0]?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid} alt={firstpost[0]?.node?.acf_customFeaturedImage?.customFeaturedImage?.altText} className="bg-cover BG bgStretch" />
              </div>
            </div>
            
            <RellaxWrapper wrapper='#container-scroller'  speed={6} className="post_info rellax">
              <div className="post_info_inner">
                <Link to={`/insights/${firstpost[0].node.slug}/`}>
                  <h2>{firstpost[0].node.title}</h2>
                </Link>
                <div className="user_info">
                  <div className="image_avatar">
                    <img src={firstpost[0].node.author.node.avatar.url.replace('http://','https://')} alt="User" />
                  </div>
                  <div className="user"><span>By </span> <Link to={'/author/'+firstpost[0].node.author.node.name.replace(/\s+/g, '-').toLowerCase()+'/'}>{firstpost[0].node.author.node.firstName}</Link></div>
                  <div className="date">{firstpost[0].node.date}</div>
                </div>
              </div>
            </RellaxWrapper>
          </Hero>
          
          <section ref={ref} className={`filters inview_offset ${inView ? 'is_inview' : ''}`}>
            <ul>
              <li className="active"><a href="/insights/">All Articles</a></li>
              {cats.map((cat) => {
                return(<li><Link to={'/insights/'+cat.slug+'/'} className={cat.slug}>{cat.name}</Link></li>)
              })}
            </ul>
            
            <select name="filters"  onChange={(e) => handleOnChange(e)} defaultValue={isActive}>
              <option data-slug="/insights/" data-cat="All Articles">All Articles</option>
              {cats.map((cat) => {
                return(<option data-slug={'/insights/'+cat.slug+'/'} data-cat={cat.name}>{cat.name}</option>)
              })}
            </select>
          </section>
          
          <section ref={ref} className={`the_posts inview_offset ${inView ? 'is_inview' : ''}`} >
            {posts.map((post) => {
              return(
                
                <article id="post-" className="post">
                  <Link className="image-link" to={`/insights/${post.node.slug}/`} title="">
                    <Img fluid={post?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid } className="bg-cover BG bgStretch" />
                  </Link>
                  {post.node.blogCats.nodes.map((cat) => {
                    return(
                      <Link to={cat.link}><div className="label">{cat.slug}</div></Link>
                    )
                  })}
                  
                  <h2>
                    <Link to={post.node.link} title="">{post.node.title}</Link>
                  </h2>
                  <div className="author_date"><span>By </span>{post.node.author.node.name }<span> | {post.node.date }</span></div>   
                </article>               
              )
            })}
          </section>

          <section className="pagination">
            <div className="wp-pagenavi" role="navigation">
                {pageNumber !== 1 ?
                    <Link className="previouspostslink" rel="previous" to={pageNumber === 2 ? `/insights/`: `/insights/page/${pageNumber-1}/`} >Prev</Link>
                : ""
                }
                {Array.from({ length: totalPages }, (_, i) => (
                    i === pageNumber - 1 ? 
                        <span aria-current="page" className="current">{i + 1}</span>
                    : 
                    <Link
                    className={i === totalPages - 1 ? 'page larger last-child' : 'page larger'}
                    to={`${i === 0 ? '/insights' : '/insights/page/'+ (i + 1)}/`}
                    >
                    {i + 1}
                    </Link>
                ))}
                {pageNumber !== totalPages ?
                    <Link className="nextpostslink" rel="next" to={`/insights/page/${pageNumber+1}/`}>Next</Link>
                : ""
                }
                
            </div>
          </section>
	</div>
	</div>

    </>
  )
}


export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $latestPostId: String!) {
    allPosts: allWpBlog(
      sort: {order: DESC, fields: date}
      filter: {id: {ne: $latestPostId}}
      limit: $postsPerPage
      skip: $offset
    ) {
        edges {
            node {
                acf_customColours {
                  customColour1
                  customColour2
                }
                title
                date(formatString: "Do MMMM YYYY")
                slug
                link
                id
                blogCats {
                nodes {
                    slug
                    link
                    uri
                }
                }
                author {
                node {
                    avatar {
                    url
                    }
                    firstName
                    slug
                    name
                    uri
                }
                }
                acf_customFeaturedImage {
                customFeaturedImage {
                    localFile {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    }
                }
                }
            }
        }
    }
    firstPost: allWpBlog(
      filter: {id: {eq: $latestPostId}}
    ) {
        edges {
            node {
                acf_customColours {
                  customColour1
                  customColour2
                }
                title
                date(formatString: "Do MMMM YYYY")
                slug
                link
                uri
                id
                blogCats {
                nodes {
                    slug
                    link
                }
                }
                author {
                node {
                    avatar {
                    url
                    }
                    firstName
                    slug
                    name
                    uri
                }
                }
                acf_customFeaturedImage {
                customFeaturedImage {
                    localFile {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    }
                }
                }
            }
        }
    }
    wpPage(title: {eq: "Blog"}) {
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema {
          raw
        }
      }
      date
      slug
    }
    allWpBlogCat(sort: {order: ASC, fields: id}) {
        nodes {
            name
            slug
            count
        }
    }
  }
`


export default Blog
